<template>
  <div class="cover-letters">
    <div v-if="data.data.coverLetters && data.data.coverLetters.length"
         class="project-info__inner-sections">
      <documents-list :documents="data.data.coverLetters"
                      mode="with-icon" />
    </div>
  </div>
</template>

<script>
  import DocumentsList from '@/components/assignment_components/one_assignment/DocumentsList';

  export default {
    components: {
      'documents-list': DocumentsList,
    },
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    }
  };
</script>
<style scoped>
.project-info__inner-sections {
  position: relative;
}

.project-info__inner-sections:first-child {
  padding-bottom: 20px;
}
</style>
