<template>
  <div class="original-documents">
    <div v-if="data.data.attachments && data.data.attachments.length"
         class="project-info__inner-sections">
      <documents-list :documents="data.data.attachments"
                      :ocr-documents="data.data.ocrAttachments"
                      mode="with-icon" />
    </div>
    <div v-if="data.data.countOfWords"
         class="project-info__inner-sections">
      <data-section :data="sectionData" />
    </div>
    <template v-if="showPriceToCustomer">
      <div class="project-info__price">
        <strong>Total Price</strong>
        <strong>{{ data.data.demanderQuoteAmount }} NOK</strong>
      </div>
    </template>
  </div>
</template>

<script>
  import DocumentsList from '@/components/assignment_components/one_assignment/DocumentsList';
  import DataSection from '@/components/assignment_components/one_assignment/assignment_info/DataSection';

  export default {
    components: {
      'documents-list': DocumentsList,
      'data-section': DataSection,
    },
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        showPriceToCustomer: false
      };
    },
    computed: {
      sectionData() {
        return {
          data: [{
            data: this.data.data?.countOfWords || 0,
            title: this.$pgettext('translation', 'Total Words')
          }]
        };
      }
    }
  };
</script>

<style scoped>
.project-info__section-title {
  margin: 5px;
  color: #000;
  font-size: 18px;
  line-height: 14px;
}

.project-info__inner-sections {
  position: relative;
}

.project-info__inner-sections:first-child {
  padding-bottom: 20px;
}

.project-info__price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}
</style>
