(<template>
  <article :class="{'in-progress': progress}"
           class="project-info is-progress-bar">
    <div v-for="(section, sectionIndex) in filteredSectionsList"
         :key="sectionIndex"
         :class="section.classes"
         class="project-info__section">
      <h3 v-if="section.title"
          class="project-info__section-title">{{ section.title }}</h3>
      <edit-button v-if="section.editable"
                   :modal-classes="section.modalClasses"
                   :section-name="section.sectionName"
                   :validate-func="section.validateFunc"
                   :source-lang="project.sourceLanguageId"
                   :fetch-func="section.fetchFunc"
                   :subtasks-types="project.subtaskTypes"
                   class="project-info__edit-btn" />
      <div v-for="(group, groupIndex) in getFilteredGroups(section.groups)"
           :key="groupIndex"
           class="project-info__inner-sections">
        <edit-button v-if="group.editable"
                     :section-name="group.sectionName"
                     :validate-func="group.validateFunc"
                     :fetch-func="group.fetchFunc"
                     class="assignment-info__edit-btn" />
        <component :is="component.componentName"
                   v-for="component in getFilteredComponents(group.components)"
                   :key="component.key"
                   :data="component"
                   class="project-info__inner-section" />
      </div>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import DataSection from '@/components/assignment_components/one_assignment/assignment_info/DataSection';
  import TranslationAccountManager from '@/components/assignment_components/one_assignment/assignment_info/TranslationAccountManager';
  import JobInfoPrototype from '@/prototypes/JobInfoPrototype';
  import ProjectInfoEditButton from '@/components/project_components/one_project/ProjectInfoEditButton';
  import OriginalDocuments from '@/components/project_components/one_project/project_info/OriginalDocuments';
  import SystemLogButton from '@/components/assignment_components/one_assignment/assignment_info/SystemLogButton';
  import CoverLetters from '@/components/project_components/one_project/project_info/CoverLetters';
  import helpers from '@/helpers';

  export default {
    components: {
      'original-documents': OriginalDocuments,
      'data-section': DataSection,
      'account-manager': TranslationAccountManager,
      'edit-button': ProjectInfoEditButton,
      'system-log-button': SystemLogButton,
      'cover-letters': CoverLetters
    },
    extends: JobInfoPrototype,
    props: {
      progress: Boolean,
      showAdminSectionOnly: {
        type: Boolean,
        default: false
      },
      showEditButton: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        domain: this.$getString('domainData', 'platformName'),
      };
    },
    computed: {
      ...mapGetters('OneProjectStore', ['project', 'isFinishedProject']),
      ...mapGetters('UserInfoStore', ['userIsEnterpriseMember', 'labelForBookingRef', 'labelForPaymentBookingRef', 'labelForCaseNumber', 'userIsInterpreter', 'userIsNotInterpreter']),
      // common info
      projectId() { return this.$route.params.id; },
      isSuitable() { return this.project.isSuitable; },
      copiesIds() { return this.project.copiesIds || []; },
      isEditableMyInformation() {
        const notVisibleOn = ['finished', 'cancelled', 'quote_rejected', 'completed'];
        return !notVisibleOn.includes(this.project.status) && this.userIsNotInterpreter && !this.project.isXTRF;
      },
      isEditableSendPerPost() {
        const notVisibleOn = ['finished', 'cancelled', 'quote_rejected', 'in_progress', 'completed'];
        return !notVisibleOn.includes(this.project.status) && this.userIsNotInterpreter && !this.project.isXTRF;
      },
      isAssignmentInfoEditable() {
        const notVisibleOn = ['finished', 'cancelled', 'quote_rejected', 'in_progress', 'completed'];
        return !notVisibleOn.includes(this.project.status) && this.userIsNotInterpreter && !this.project.isXTRF;
      },
      isAttachmentsEditable() {
        const notVisibleOn = ['finished', 'cancelled', 'quote_rejected', 'in_progress', 'completed'];
        return !notVisibleOn.includes(this.project.status) && this.project.waitingForCustomer;
      },
      bookingReferenceTitle() {
        return this.labelForBookingRef || this.$pgettext('translation', 'Booking reference');
      },
      paymentBookingReferenceTitle() {
        return this.labelForPaymentBookingRef || this.$pgettext('translation', 'Payment booking reference');
      },
      caseNumberTitle() {
        return this.labelForCaseNumber || this.$pgettext('translation', 'Case number');
      },
      deadlineHour() {
        const given = this.$moment(this.project.externalDeadline);
        const today = this.$moment();
        return given.diff(today, 'hours') < 0;
      },
      // section configs
      sectionsList() {
        return [
          {
            title: this.$gettextInterpolate(this.$gettext('%{domain} - Admin tools'), {
              domain: this.domain
            }),
            editable: false,
            ...this.adminSectionData,
            sectionName: 'adminInformationSection',
            modalClasses: ['sk-modal--dark'],
            classes: ['project-info__section--dark'],
            condition: this.showAdminSectionOnly
          },
          {
            title: this.isSecretaryAssignment ? this.$pgettext('translation', 'Secretary assignment') : this.$pgettext('translation', 'Assignment information'),
            ...this.projectInfoSectionData,
            sectionName: 'mainProjectInformationSection',
            validateFunc: 'validateProjectInformation',
            fetchFunc: 'OneProjectStore/preselectForProjectInformationSectionEdit',
            condition: !this.showAdminSectionOnly,
            modalClasses: ['sk-modal--invoice'],
            editable: this.isAssignmentInfoEditable && !this.deadlineHour
          },
          {
            title: this.$pgettext('translation', 'Original Documents'),
            sectionName: 'projectAttachmentsSection',
            validateFunc: 'validateAttachedFilesOnly',
            fetchFunc: 'OneProjectStore/preselectProjectAttachments',
            condition: !this.userIsInterpreter && (this.project.attachments?.length || this.project.countOfWords) && !this.showAdminSectionOnly,
            groups: [{
              components: [{
                condition: true,
                componentName: 'original-documents',
                key: Math.random(),
                data: this.project
              }]
            }],
            editable: this.isAttachmentsEditable
          },
          {
            title: this.userIsNotInterpreter ? this.$pgettext('translation', 'My information') : this.$pgettext('translation', 'Customer information'),
            ...this.myInfoSectionData,
            sectionName: 'personalInformationSection',
            validateFunc: 'validatePersonalInfoSection',
            fetchFunc: 'PostingNotifierStore/fetchDataForPersonalInformationSectionEdit',
            condition: !this.showAdminSectionOnly && (this.userIsInterpreter ? this.isSuitable : true),
            editable: this.isEditableMyInformation && !this.deadlineHour,
            modalClasses: ['sk-modal--invoice'],
          },
          {
            sectionName: 'sendPerPostSection',
            title: this.$pgettext('translation', 'Send per Post'),
            editable: this.isEditableSendPerPost && !this.deadlineHour,
            condition: !this.showAdminSectionOnly && this.project.deliveryMethod === 'post',
            validateFunc: 'validateCoverLettersSection',
            groups: [{
              components: [{
                condition: this.project.sendByPostAddress,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.$pgettext('translation', 'Address'), data: this.project?.sendByPostAddress || ''}]
              }, {
                condition: true,
                componentName: 'cover-letters',
                key: Math.random(),
                data: this.project
              }]
            }]
          },
          {
            sectionName: 'invoiceInformationSection',
            title: this.$pgettext('translation', 'Invoice information'),
            editable: !this.project.isXTRF,
            condition: !this.userIsInterpreter && !this.showAdminSectionOnly,
            modalClasses: ['sk-modal--invoice'],
            validateFunc: 'validateInvoiceSection',
            fetchFunc: 'PostingNotifierStore/fetchDataForInvoiceInformationSectionEdit',
            groups: [{
              components: [{
                condition: this.project.bookingReference && this.userIsEnterpriseMember,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.bookingReferenceTitle, data: this.project?.bookingReference || ''}]
              }, {
                condition: this.project.paymentBookingReference && this.userIsEnterpriseMember,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.paymentBookingReferenceTitle, data: this.project?.paymentBookingReference || ''}]
              }, {
                condition: this.project.caseNumber && this.userIsEnterpriseMember,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.caseNumberTitle, data: this.project?.caseNumber || ''}]
              }, {
                condition: this.project.clientName && this.userIsEnterpriseMember,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.$pgettext('translation', 'Client name:'), data: this.project?.clientName || ''}]
              }, {
                condition: this.project.clientId && this.userIsEnterpriseMember,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.$pgettext('translation', 'Client ID'), data: this.project?.clientId || ''}]
              }, {
                condition: this.project?.paymentMethod?.orgName && this?.project?.paymentMethod?.orgNumber,
                componentName: 'data-section',
                key: Math.random(),
                data: [{title: this.$gettext('Invoice'), data: (this.project?.paymentMethod?.orgName + '/ Org. :' + this.project?.paymentMethod?.orgNumber) || ''}]
              }
              ]
            }],
          }, {
            groups: [{
              components: [{
                condition: !this.userIsInterpreter && !this.showAdminSectionOnly,
                componentName: 'account-manager',
                key: Math.random()
              }]
            }],
            condition: true
          }
        ];
      },
      adminSectionData() {
        return {
          groups: [{
            components: [{
              condition: this.$isGodmode(),
              componentName: 'system-log-button',
              key: Math.random(),
              data: {isOnTranslation: true}
            }]
          }]
        };
      },
      filteredSectionsList() {
        return this.sectionsList.filter((section) => {
          if (section.condition) {
            const availableComponentsGroup = section.groups.find((group) => {
              return group.components.some((component) => !!component.condition);
            });
            if (availableComponentsGroup) return section;
          }
        });
      },
      projectInfoSectionData() {
        return {
          groups: [{
            components: [{
                           condition: true,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [this.publishedAtObj]
                         }, {
                           condition: this.project.originalOrderId && this.userIsNotInterpreter,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [{
                             title: this.$gettext('Copied from'),
                             data: this.getProjectLink(this.project.originalOrderId)
                           }]
                         }, {
                           condition: this.copiesIds && this.copiesIds.length && this.userIsNotInterpreter,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [{
                             title: this.$gettext('Copied to'),
                             data: this.copiesIds
                               .map((orderId) => this.getProjectLink(orderId, 'assignment-info__delimiter'))
                               .join(', ')
                           }]
                         }, {
                           condition: true,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [this.targetLangsObj]
                         },
                         {
                           condition: this.project.alternativeEnglishTargetLanguageAllowed,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [this.alternativeEnglishLanguage]
                         },
                         {
                           condition: this.userIsNotInterpreter,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [this.deadlineObj]
                         },
                         {
                           condition: this.userIsNotInterpreter && this.projectSubtasksTypes,
                           componentName: 'data-section',
                           key: Math.random(),
                           data: [this.typeObj]
                         }
            ]
          }, {
            components: [{
              condition: (!this.userIsInterpreter || !this.isFinishedProject) && this.project.instructionsFromDemander,
              componentName: 'data-section',
              key: Math.random(),
              data: [this.teamInstructionObj]
            },
            ]
          }, {
            components: [{
              condition: this.userIsInterpreter && !this.isFinishedProject && this.project.instructionsFromProjectManager,
              componentName: 'data-section',
              key: Math.random(),
              data: [this.instructionsFromSalita]
            },
            ]
          }, {
            components: [
              {
                condition: this.userIsInterpreter && !this.isFinishedProject,
                componentName: 'original-documents',
                key: Math.random(),
                data: this.project
              }
            ]
          }
          ]
        };
      },
      publishedAtObj() {
        const template = this.$pgettext('translation', 'Created at: %{date}');
        const date = this.$moment(this.project.createdAt).format('DD.MM.YYYY');
        return {
          data: '#' + this.project.id,
          title: this.$gettextInterpolate(template, {date: date})
        };
      },
      alternativeEnglishLanguage() {
        return {
          title: this.$gettext('Alternative language'),
          data: this.$gettext('English')
        };
      },
      languagesList() { return this.$store.state.selectsData.language || []; },
      sourceLanguage() {
        return this.languagesList.find((lang) => {
          return lang.id === this.project.sourceLanguageId;
        });
      },
      targetLanguages() {
        if (this.project && this.project.targetLanguages) {
          return this.project.targetLanguages.map((item) => {
            return this.languagesList.find((lang) => lang.id === item);
          });
        } else {
          return [];
        }
      },
      targetLanguagesConcat() {
        const sourceLang = this.sourceLanguage ? this.sourceLanguage.name + ' - ' : '';
        return this.targetLanguages?.map((lang) => (sourceLang + lang?.name)).join('<br>');
      },
      targetLangsObj() {
        return {
          title: this.$pgettext('translation', 'Languages'),
          data: this.targetLanguagesConcat || '',
        };
      },
      deadlineObj() {
        const deadlineDate = this.$moment(this.project.externalDeadline).format('ddd DD MMM @HH:mm') === 'Invalid date'
          ? '-' : this.$moment(this.project.externalDeadline).format('ddd DD MMM @HH:mm');
        return {
          data: deadlineDate,
          title: this.$pgettext('translation', 'Deadline')
        };
      },
      typeObj() {
        return {
          title: this.$gettext('Type'),
          data: this.projectSubtasksTypes
        };
      },
      projectSubtasksTypes() {
        return helpers.getProjectInfo.subtasksTypes(this, this.project.subtaskTypes || []);
      },
      teamInstructionObj() {
        let title = '';
        if (this.userIsInterpreter) {
          title = this.$pgettext('translation', 'Description:');
        } else {
          title = this.$pgettext('translation', 'Information for our translator team');
        }
        const lightClass = this.project.instructionsFromDemander ? ['data-section__data--light'] : [];
        return {
          title: title,
          data: this.project.instructionsFromDemander || '-',
          classes: lightClass,
        };
      },
      instructionsFromSalita() {
        const title = this.$gettextInterpolate(this.$pgettext('translation', 'Instructions from %{domain} for translator'), {
          domain: this.domain
        });
        const lightClass = this.project.instructionsFromProjectManager ? ['data-section__data--light'] : [];
        return {
          title: title,
          data: this.project.instructionsFromProjectManager || '-',
          classes: lightClass
        };
      },
      ownerObj() {
        let owner = '';
        owner = this.project?.owner?.name ? owner.concat(this.project?.owner?.name, '<br>') : '';

        return {
          title: this.$pgettext('translation', 'Ordered by'),
          data: owner
        };
      },
      organization() {
        let paymentMethod = '';
        const orgNumber = this.project?.paymentMethod?.orgNumber || '';
        const orgName = this.project?.paymentMethod?.orgName || '';

        if (orgName) {
          paymentMethod = paymentMethod.concat(orgName, '<br>');
        }
        if (orgNumber) {
          paymentMethod = paymentMethod.concat('Org: ' + orgNumber, '<br>');
        }
        return {
          title: this.$gettext('Organization'),
          data: paymentMethod
        };
      },
      interpreterDescription() {
        return {
          title: this.$pgettext('translation', 'Description'),
          data: this.project
        };
      },
      getPreviewOrder() {
        let owner = '';
        const templatePhone = this.project?.owner?.phone
          ? `<a class="sk-link sk-link--default preview" href="tel:${this.project?.owner?.phone}">${this.project?.owner?.phone}</a>`
          : '';
        owner = owner.concat(templatePhone);
        const templateEmail = this.project?.owner?.email
          ? `<a class="sk-link sk-link--default preview" href="mailto:${this.project?.owner?.email}">${this.project?.owner?.email}</a>`
          : '';
        owner = owner.concat(templateEmail, '<br>');
        return owner;
      },
      myInfoSectionData() {
        if (this.userIsInterpreter) {
          return {
            groups: [{
              components: [{
                             condition: true,
                             componentName: 'data-section',
                             key: Math.random(),
                             data: [{title: this.$gettext('Organization:'), data: this.project?.enterprise?.name || ''}]
                           },
                           {
                             condition: true,
                             componentName: 'data-section',
                             key: Math.random(),
                             data: [{title: this.$pgettext('translation', 'Department'), data: this.project?.department?.name || ''}]
                           }
              ]
            }]
          };
        } else {
          return {
            groups: [{
              components: [{
                             condition: true,
                             componentName: 'data-section',
                             key: Math.random(),
                             preview: this.getPreviewOrder,
                             data: [this.ownerObj]
                           }, {
                             condition: true,
                             componentName: 'data-section',
                             key: Math.random(),
                             data: [{title: this.$pgettext('translation', 'Department'), data: this.project?.department?.name || ''}]
                           },
                           {
                             condition: true,
                             componentName: 'data-section',
                             key: Math.random(),
                             data: [this.organization]
                           }
              ]
            }]
          };
        }
      },
    // processed data for sections
    },
    methods: {
      getFilteredGroups(groups) {
        return groups.filter((group) => {
          return group.components.some((component) => !!component.condition);
        });
      },
      getFilteredComponents(components) {
        return components.filter((component) => component.condition);
      },
      getProjectLink(orderId, className) {
        const routeName = this.userIsNotInterpreter ? 'BuyerOneProject' : 'ServerOneProject';
        const link = orderId && this.$router.resolve(this.$makeRoute({name: routeName, params: {id: orderId}})).href;
        return `<a class="sk-link sk-link--gray-bg ${className}" target="_blank" href="${link}">${orderId}</a>`;
      },
    }
  };
</script>

<style>
.sk-modal--invoice .payment-method .sk-select__list {
  top: -2px;
  max-height: 200px;
  transform: translateY(-100%);
}
</style>

<style scoped>
.project-info {
  display: block;
  width: 100%;
}

.project-info__section {
  position: relative;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: #fff;
}

.project-info__section--dark {
  border-radius: 10px;
  background-color: #aaa8be;
}

.project-info__section-title {
  padding-top: 20px;
  color: #000;
  font-size: 18px;
  line-height: 14px;
}

.project-info__inner-sections {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0003;
}

.project-info__inner-sections:last-child {
  border-bottom: none;
}

.project-info__inner-section {
  padding-bottom: 20px;
}

.project-info__inner-section:last-child {
  padding-bottom: 0;
}

.project-info__edit-btn {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 1;
}
</style>
